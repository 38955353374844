import React from "react";
import styled from "styled-components";
import { CircularProgress, Box, Avatar } from "@mui/material";

// @ts-expect-error Not a module
import logo from "../assets/logo.svg";

function Loading() {
  return (
    <Overlay>
      <img className="logo" src={logo}></img>
      <CircularProgress className="progress" />
    </Overlay>
  );
}

const Overlay = styled.div`
  position: absolute;

  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;

  background-color: #2a426f;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;

  .logo {
    margin-top: 35vh;
    margin-bottom: 30px;
    width: 20vh;
    height: 20vh;
  }
  .progress {
  }
`;

export default Loading;
