import React, { useContext, useEffect } from "react";

import { navigate } from "gatsby";

import Loading from "../components/loading";
import { AuthContext } from "../context/auth";

const IndexPage = ({ location }) => {
  const { user, isLoading } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoading) {
      if (user) {
        navigate("/dashboard/report");
      } else {
        navigate("/login" + location.search);
      }
    }
  }, [user, isLoading]);
  return <Loading />;
};

export default IndexPage;
